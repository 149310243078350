<template>
    <div>
        <list></list>
    </div>
</template>

<script>
// import list from '@/components/pharmacy/list'
import list from '@/components/pharmacy/medicineList'
export default {
    name:'shoppingCar',
    components:{
        list
    }
}
</script>

<style lang="less" scoped>

</style>