<template>
    <div>
        <div class="cl cardbox" v-for="(item, index) in item" :key="index">
            <van-checkbox v-model="checked">复选框</van-checkbox>
            <div class="card">
                <!-- <div class="top">
                    <img src="../../assets/img/help_0.png" alt="" />
                    <span>同仁堂大药房(光谷大道店)</span>
                </div> -->
                <div class="tmail">
                    <img src="../../assets/img/help_0.png" alt="" class="" />
                    <div class="">
                        <h4>硝苯地平控释片</h4>
                        <p class="size">500g 直径10mm（药品规格、剂量）</p>
                        <p>不支持7天无理由退换</p>
                        <p><span>¥ 36.00</span> <!-- <em>x1</em> --></p>

                    </div>
                </div>
                <!-- <div class="btnbox">
                    <p>应付金额:258.00</p>
                    <div>
                    <span>查看物理</span>
                    <span class="on">确认收货</span>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        list: {
            default: new Array(),
            type: Array
        }
    }
}
</script>

<style lang="less" scoped>
.cardbox {
  padding: 16px;
  padding-bottom: 0;
  .card {
    padding: 16px;
    background: #fff;
    text-align: left;
    .top {
      img {
        width: 30px;
        height: 30px;
        margin-right: 8px;
        margin-top: -4px;
      }
      span {
        font-size: 16px;
        color: #2d2d2d;
        vertical-align: top;
      }
    }
    .tmail {
      display: flex;
      padding-bottom: 16px;
      border-bottom: 1px solid #efefef;
      img {
        width: 80px;
        height: 80px;
        margin-right: 13px;
      }
      h4 {
        font-size: 16px;
        color: rgba(45, 45, 45, 1);
        font-weight: 700;
        margin-bottom: 6px;
      }
      div {
        width: 230px;
      }
      p {
        font-size: 12px;
        margin-bottom: 8px;
        span {
          color: rgba(251, 81, 81, 1);
          font-size: 14px;
        }
        em {
          float: right;
          font-style: normal;
        }
      }
      .size {
        font-size: 12px;
        color: rgba(29, 174, 252, 1);
        margin-bottom: 8px;
      }
    }
    .btnbox {
      display: flex;
      padding-top: 11px;
      justify-content: space-between;
      .daifahuo{
            color: rgba(29,174,252,1);
        
      }
      div {
        span {
          display: inline-block;
          width: 68px;
          height: 32px;
          border: 1px solid #c2c2c2;
          border-radius: 8px;
          line-height: 32px;
          text-align: center;
          font-size: 12px;
          margin-left: 16px;
          &.on{
            color: rgba(29,174,252,1);
          border: 1px solid rgba(29,174,252,1);

          }
        }
      }
    }
  }
}
</style>